.react-datepicker {
  border-radius: 5px;
  border: 1px solid lightgray;
  padding: 0.5em;
  font-family: Arial, sans-serif;
}

.custom-datepicker-popper {
  padding-top: 0.3em !important;
}

.react-datepicker__input-container {
  width: 100%;
  display: table;
}

.react-datepicker__navigation {
   height: 50px; 
}

.react-datepicker__triangle::after {
  visibility: hidden;
}

.react-datepicker__triangle::before {
  visibility: hidden;
}

.react-datepicker__header {
  border-bottom: none;
  background-color: white;
}

/* .react-datepicker__navigation--next {
  background-color:blueviolet;
}

.react-datepicker__navigation--previous {
  background-color:blueviolet;
} */

.react-datepicker__month-container {
  background-color: white;
  font-weight: normal;
}

.react-datepicker__day--outside-month {
  visibility: hidden;
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
  height: 30px;
}

.react-datepicker__day-names {
  font-weight: bold;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
  color: white;
  background-color: #0075db;
}

.react-datepicker-wrapper {
  max-width: 100%;
  width: 100%;
}

.custom-day {
  color: red;
}

.change-month-icon {
  border: none;
  background-color: #FFF;
  font-size: 16px;
  color: #0075db;
}

.select-month {
  border: none;
  font-size: 16px;
  width: 100px;
  text-align: center;
/*   -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: ''; */
}
