html,
body,
#root {
  height: 100%;
}

.swiper {
  width: 100%;
  height: 500px;
}

.swiper-slide {
  text-align: center;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-pagination-bullet {
  --swiper-theme-color: orange;
}
.swiper-pagination {
  display: none;
}

.mySwiper {
  height: 460px !important;
}

.tooltip {
  position: relative;
  display: inline-block;
  width: 95%;
}

.tooltip-section {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.tooltip .tooltip-text {
  visibility: hidden;
  width: 100%;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 2px 6px;

  /* Position the tooltip */
  position: absolute;
  z-index: 999;
  opacity: 0;
  transition: opacity 0.4s ease, visibility 0.4s ease;
  word-break: break-all;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.rti--container {
  font-size: 12px;
  width: 100%;
  border-color: #f1f1f2;
  margin-top: 2px;
  padding-left: 8px;
}

.promo-modal .react-datepicker-popper {
  position: absolute !important;
  top: -100px !important;
}

.side-bar-font {
  font-weight: 400 !important;
}

.bg_billed {
background-image: url(../../assests/images/EmployeeOrder/Subtract\ 2.png);
object-fit: cover;
background-repeat: no-repeat;
}

@media screen and (min-width: 394px) and (max-width: 454px) {
  .mySwiper {
    height: 520px !important;
  }
}

@media screen and (min-width: 455px) and (max-width: 523px) {
  .mySwiper {
    height: 570px !important;
  }
}

@media screen and (min-width: 524px) and (max-width: 603px) {
  .mySwiper {
    height: 635px !important;
  }
}

@media screen and (min-width: 604px) and (max-width: 663px) {
  .mySwiper {
    height: 680px !important;
  }
}

@media screen and (min-width: 664px) and (max-width: 768px) {
  .mySwiper {
    height: 770px !important;
  }
}
