body{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.rc-time-picker-input{
  height: 42px; 
  font-size: 14px;
  border-color: #F1F1F2;
  border-radius: 8px;
}

table th {
  font-weight: 400 !important;
}

.active-recent-order{
  filter: invert(49%) sepia(96%) saturate(590%) hue-rotate(324deg) brightness(96%) contrast(93%);
}
.active-recent-order-default{
  filter: invert(21%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(10%) contrast(10%);
}

.white-arrow {
  filter: invert(100%) brightness(150%);
}