/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;


*::-webkit-scrollbar {
  display: none; 
}

* {
  scrollbar-width: none; 
  -ms-overflow-style: none;
}

@layer base {
    @font-face {
      font-family: "Geist";
      font-style: normal;
      font-weight: 100 900;
      font-display: optional;
      src: url(/src/assests/fonts/geist.woff2) format("woff2");
    }
  }

  @media print {
    .print-exclude {
      display: none !important;
    }
  
    .modal-print {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: white; 
    }
  }
  